import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {HashRouter, BrowserRouter} from 'react-router-dom'
import {Provider} from 'react-redux'
import store from './redux/store'
import * as serviceWorker from './serviceWorker';

const Router = process.env.NODE_ENV === 'development' ? HashRouter: BrowserRouter;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store} >
      <Router>
        {/* <Elements stripe={stripePromise} > */}
          <App />
        {/* </Elements> */}
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
